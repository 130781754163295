@font-face {
  font-family: MaplestoryLight;
  src: url("../../../public/assets/MaplestoryLight.ttf");
}
* {
    -webkit-tap-highlight-color: transparent;
    box-sizing: border-box;
}
.take-it-button {
  position: fixed;
  bottom: 25px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
  padding: 0;
  font-size: 18px;
  color: #fff;
  text-shadow: 1px 1px 1px rgb(221 122 2 / 50%);
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 44px;
  overflow: hidden;
  border: 0;
  background: url("../../../public/assets/images/8thwall-scene-page/bg/bg-btn01.png") no-repeat;
  background-size: 150px 44px;
  cursor: pointer;
  user-select: none;
  transition: 0.3s;
}
.take-it-button:active {
  /*background: orange;*/
  /*transform: scale(1.2);*/
}
.take-it-button.orange {
  /*background: orange;*/
  background-image: url("../../../public/assets/images/8thwall-scene-page/bg/bg-btn-orange.png");
  background-size: 150px 44px;
}

.scene-top-img-title{
    position: fixed;
    top: 35px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1000;
    max-width: 80%;
    height: 50px;
    user-select: none;
}

.change-gif-button{
  position: fixed;
  bottom: 50%;
  transform: translateY(-50%);
  z-index: 1000;
  padding: 0;
  font-size: 18px;
  color: #fff;
  text-shadow: 1px 1px 1px rgb(221 122 2 / 50%);
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 30px;
  overflow: hidden;
  border: 0;
  background: url("../../../public/assets/images/8thwall-scene-page/bg/bg-btn01.png") no-repeat;
  background-size: 50px 30px;
  cursor: pointer;
}
.change-gif-button.prev{
    left: 10px;
}
.change-gif-button.next{
    right: 10px;
}
.show-gif {
    width: 90%;
    max-height: calc(100% - 180px);
    top: 70px;
    left: 50%;
    transform: translateX(-50%);
    position: fixed;
    z-index: 9;
    object-fit: contain;
    object-position: center;
}

.slider-indicators{
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    gap: 10px;
    justify-content: center;
    position: fixed;
    bottom: 80px;
    left: 0;
    width: 100%;
    z-index: 9;
}
.slider-indicators .slider-indicator{
    width: 10px;
    height: 10px;
    background: #be9ec8;
    border-radius: 50%;
    cursor: pointer;
}
.slider-indicators .slider-indicator.active{
    background: #7f3f91;
}


/*First Step*/
.page-item-wrap {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 10;

    backdrop-filter: blur(10px);
}
.page-item-wrap.justify-space-around {
    justify-content: space-around;
}
.page-item-wrap.bg-transparent {
    background: transparent;
}
.page-item-wrap.bg-white {
    background: white;
}
.page-item-wrap .box-item-wrap {
    width: calc( 100% - 100px );
    height: auto;
    padding: 30px 0;
    border-radius: 20px;
    background: rgba(19,31,41,.8);
    border: 3px solid #72cfbc;
    position: relative;
}
.page-item-wrap.bg-transparent .box-item-wrap,
.page-item-wrap.bg-white .box-item-wrap{
    background: transparent;
    border: 0px;
}
.page-item-wrap .box-item-wrap.bg-circle:after {
    content: '';
    position: absolute;
    height: 70px;
    width: 70px;
    left: 0;
    top: 0;
    z-index: 0;
    background-image: url("../../../public/assets/images/8thwall-scene-page/bg/bg-circle-green-border.png");
    background-size: contain;
    background-repeat: no-repeat;
    opacity: .3;
}
.page-item-wrap .box-item-wrap .btitle {
    width: 100%;
    height: auto;
    overflow: hidden;
    text-align: center;
    font-size: 34px;
    color: #fff;
    font-weight: 500;
    line-height: 1.36em;
    letter-spacing: -1.29px;
    margin-bottom: 20px;
}
.page-item-wrap.bg-transparent .box-item-wrap .btitle,
.page-item-wrap.bg-white .box-item-wrap .btitle{
    color: #000;
    text-shadow: 0px 0px 3px #fff;
}
.page-item-wrap .box-item-wrap .bmsg {
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #fff;
    font-family: "MaplestoryLight";
    width: calc( 100% - 60px );
    height: auto;
    line-height: 1.25em;
    letter-spacing: -0.72px;
    font-size: 18px;
    position: relative;
    z-index: 1;
}

.page-item-wrap.not-found-popup .box-item-wrap{
    overflow: hidden;
    padding: 30px;
}
.page-item-wrap.not-found-popup .box-item-wrap .bmsg{
    margin: 30px auto;
}

.page-item-wrap.bg-transparent .box-item-wrap .bmsg,
.page-item-wrap.bg-white .box-item-wrap .bmsg{
    color: #000;
    text-shadow: 0px 0px 3px #fff;
}
.page-item-wrap .box-item-wrap .bpic {
    margin-top: 30px;
    width: 100%;
    height: auto;
    /*overflow: hidden;*/
    text-align: center;
}
.page-item-wrap .box-item-wrap .bpic.align-right {
    text-align: right;
}
.page-item-wrap .box-item-wrap .bpic.animation img {
    animation: mover01 1s infinite alternate;
}
@keyframes mover01 {
    0% { transform: translateY(0); }
    100% { transform: translateY(-10px); }
}

.page-item-wrap .box-item-wrap .bpic img {
    max-height: 80px;
}
.page-item-wrap .box-item-wrap .bpic img.bigger {
    max-height: 150px;
}
.page-item-wrap .btn-item-wrap {
    width: 100%;
    height: auto;
    overflow: hidden;
    margin-top: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.page-item-wrap .btn-item-wrap button {
    padding: 0;
    font-size: 16px;
    color: #fff;
    text-shadow: 1px 1px 1px rgb(221 122 2 / 50%);
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 150px;
    height: 44px;
    overflow: hidden;
    border: 0;
    background: url("../../../public/assets/images/8thwall-scene-page/bg/bg-btn01.png") no-repeat;
    background-size: 150px 44px;
    cursor: pointer;
}

/*Second Step*/
.camera-frame{
  position: fixed;
  z-index: 9;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  height: 50%;
}
.camera-frame .left,
.camera-frame .right
{
  position: absolute;
  width: 30px;
  height: 30px;
}
.camera-frame .left:after,
.camera-frame .right:after{
  content: '';
  width: 20%;
  height: 100%;
  position: absolute;
  background: #f9f9f9;
}

.camera-frame .left:before,
.camera-frame .right:before{
  content: '';
  width: 100%;
  height: 20%;
  position: absolute;
  background: #f9f9f9;
}

.camera-frame .left{
  left: 10px;
}

.camera-frame .left:after, .camera-frame .left:before {
  left: 0;
}

.camera-frame .right:after, .camera-frame .right:before {
  right: 0;
}

.camera-frame .top:after, .camera-frame .top:before {
  top: 0;
}

.camera-frame .bottom:after, .camera-frame .bottom:before {
  bottom: 0;
}

.camera-frame .right{
  right: 10px;
}
.camera-frame .top{
  top: 10px;
}
.camera-frame .bottom{
  bottom: 10px;
}

.camera-msg {
    position: absolute;
    top: 10%;
    left: 0;
    width: 100%;
    height: auto;
    overflow: hidden;
    text-align: center;
    line-height: 1.21em;
    letter-spacing: -0.66px;
    font-size: 16px;
    color: #fffefe;
    font-weight: bold;
    z-index: 9;
}
.skip-button{
    position: fixed;
    z-index: 9;
    bottom: 50px;
    right: 20px;
    background: #000;
    color: #fff;
    padding: 8px 12px;
    border: 0;
    border-radius: 5px;
    font-size: 15px;
    opacity: .7;
    transition: opacity .3s;
}

.skip-button:active{
    opacity: .3;
}


.fitem-wrap {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 135px;
    overflow: hidden;
    background: url("../../../public/assets/images/8thwall-scene-page/bg/bg-bottom.png") no-repeat left top;
    background-color: rgba(0,0,0,.5);
    background-size: cover;
    z-index: 9;
}
.fitem-wrap .fpic {
    width: 80px;
    height: 80px;
    overflow: hidden;
    background: url("../../../public/assets/images/8thwall-scene-page/bg/bg-circle.png") no-repeat center center;
    background-size: cover;
    margin: 20px auto 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
}
.fitem-wrap .fpic img {
    max-width: 48px;
    max-height: 56px;
}
.fitem-wrap .tooltip {
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: auto;
    padding: 5px 10px;
    border-radius: 20px;
    background: rgba(255,255,255,1);
    border: 2px solid #a6a6a7;
    box-shadow: inset 0 1px 14px rgb(255 255 255);
    text-align: center;
    font-size: 15px;
    color: #202a31;
    font-weight: bold;
    line-height: 1.22em;
    letter-spacing: -1.15px;
}
.fitem-wrap .tooltip:before {
    content: "";
    width: 20px;
    height: 20px;
    overflow: hidden;
    display: block;
    background: url("../../../public/assets/images/8thwall-scene-page/bg/bg-moseri01.png") no-repeat left top;
    background-size: 12px 12px;
    position: absolute;
    left: 50%;
    top: -12px;
    margin-left: -10px;
}

/*Third step*/
.page-item-wrap.not-found-popup-hint {
    background: url("../../../public/assets/images/8thwall-scene-page/bg/blue-bg.jpg") no-repeat center top;
    background-size: cover;
}
.page-item-wrap.not-found-popup-hint .box-item-wrap {
    padding: 30px 0;
}
.page-item-wrap .box-item-wrap.type01 {
    padding-bottom: 10px;
}
.page-item-wrap .box-item-wrap .bclose {
    width: 100%;
    height: auto;
    overflow: hidden;
    text-align: right;
    position: relative;
    z-index: 1;
}
.box-item-wrap .bclose a {
    display: inline-block;
    cursor: pointer;
}
.box-item-wrap .bclose a img {
    width: 15px;
}
.box-item-wrap .help-item-wrap {
    margin-top: 20px;
    position: relative;
    background: url("../../../public/assets/images/8thwall-scene-page/bg/bg-hint-line.png") no-repeat center top;
    background-size: 222px 2px;
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}
.box-item-wrap .help-item-wrap button {
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 38px;
    border: 0;
    background: transparent;
    padding: 0;
}
.box-item-wrap .help-item-wrap button img {
    width: 80px;
    height: 38px;
}

.box-item-wrap .help-item-wrap .tooltip {
    display: block;
    right: -36px;
    top: -27px;
    position: absolute;
    width: 100px;
    height: auto;
    padding: 10px;
    border-radius: 20px;
    background: rgba(108,195,178,1);
    border: 2px solid #5cdbc2;
    box-shadow: inset 0 1px 14px rgb(255 255 255 / 70%);
    text-align: center;
    font-size: 12px;
    color: #131f2a;
    font-weight: bold;
    line-height: 1.22em;
    letter-spacing: -1.15px;
}
.box-item-wrap .help-item-wrap .tooltip:before {
    content: "";
    width: 12px;
    height: 12px;
    overflow: hidden;
    display: block;
    background: url("../../../public/assets/images/8thwall-scene-page/bg/bg-moseri.png") no-repeat left top;
    background-size: 12px 12px;
    position: absolute;
    left: -12px;
    top: 50%;
    margin-top: -6px;
}

/*not found page tooltip*/
.page-item-wrap.not-found-popup-hint .box-item-wrap {
    border: 0;
    height: calc(100% - 150px);
    border-radius: 0;
    background: transparent;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    position: relative;
}

.page-item-wrap.not-found-popup-hint .box-item-wrap .bclose {
    position: absolute;
    right: -20px;
    top: -45px;
    width: 45px;
    height: 45px;
    background: transparent;
    border: 0;
    padding: 0;
    box-sizing: border-box;
}

.page-item-wrap.not-found-popup-hint .box-item-wrap .bclose img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.page-item-wrap.not-found-popup-hint .box-item-wrap .title {
    color: #fff;
    text-align: center;
    font-family: "MaplestoryLight";
    font-size: 22px;
}
.page-item-wrap.not-found-popup-hint .box-item-wrap .title .sub-header {
    margin-bottom: 5px;
}
.page-item-wrap.not-found-popup-hint .box-item-wrap .title .header {
    font-size: 25px;
}
.page-item-wrap.not-found-popup-hint .box-item-wrap .target-image {
    background-image: url(../../../public/assets/images/8thwall-scene-page/ico/image-eff-light-bg.png);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.page-item-wrap.not-found-popup-hint .box-item-wrap .target-image img {
    max-height: 100px;
    max-width: 70%;
}
.page-item-wrap.not-found-popup-hint .box-item-wrap .bmsg {
    margin: 0;
    width: 100%;
}

  
.drag-drop-item-wrap{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 10;
    backdrop-filter: blur(10px);
    display:flex;
    justify-content:space-around;
    align-items:center;
    padding: 20px;
}
.drag-drop-item-wrap .scene-top-img-title{
    position: static;
    transform: none;
}
  
.drag-drop-item-wrap .droptarget {
  width: 100%;
}
.drag-drop-item-wrap .droppable { 
  width: 100%;
  height: auto;
}
.highlighted .droppable {
  /*background-color: lightblue*/
}
@media (max-width:  767px){
  .drag-drop-item-wrap {
    flex-direction: column;
  }
}